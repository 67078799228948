import * as React from 'react'
import Layout from '../../components/layout'
import { Titulo } from '../../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'


export default function atencion () {
    const data=[
        {id:'1', name:'1° Básico', hAtencion:'Lunes de 13:00 a 13:40 hrs'},
        {id:'2', name:'2° Básico', hAtencion:'Martes de 13:00 a 13:40 hrs'},
        {id:'3', name:'3° Básico', hAtencion:'Martes de 13:00 a 13:40 hrs'},
        {id:'4', name:'4° Básico', hAtencion:'Martes de 13:00 a 13:40 hrs'},
        {id:'5', name:'5° Básico', hAtencion:'Lunes de 13:00 a 13:40 hrs'},
        {id:'6', name:'6° Básico', hAtencion:'Miércoles de 13:00 a 13:40 hrs'},
        {id:'7', name:'7° Básico', hAtencion:'Lunes de 13:00 a 13:40 hrs'},
        {id:'8', name:'8° Básico', hAtencion:'Martes de 13:00 a 13:40 hrs'}
    ]
    return (
        <Layout>
            <Titulo title="Atencion apoderados"  />
            <Container>
                <Row className="text-center justify-content-center">
                    {data && data.map(e=>(
                        <Col key={e.id} md={4} className="mt-3">
                            <Card className="bg-dark">
                                <Card.Body>
                                    <strong><h5>{e.name}</h5></strong>
                                    <h6>{e.hAtencion}</h6>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                    <Col md={12} className="mb-3"></Col>
                </Row>
            </Container>
        </Layout>
    )
}